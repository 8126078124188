<!-- 购物车 -->
<template>
    <div class="container">
        <titleText title="购物车"/>
        <Empty v-if="goodList.length == 0" type="cart" title="还没有添加商品哦，快去挑选商品吧~" />
        <template v-else>
            <div class="th flex align-c">
                <div class="th-checked">
                    <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                </div>
                <div class="th-info">商品信息</div>
                <div class="th-price">单价</div>
                <div class="th-amount">数量</div>
                <div class="th-sum">金额</div>
                <div class="th-operation">操作</div>
            </div>
            <div class="mt-20 cart-container">
                <el-checkbox-group v-model="checkedItems" @change="handleCheckedItemsChange">
                    <div class="tr flex font-12 " v-for="(item, index) in goodList" :key="index">
                        <div class="tr-checked align-self-c">
                            <el-checkbox :label="item.id" :key="item.id"><br></el-checkbox>
                        </div>
                        <div class="tr-info flex">
                            <div class="good-image" @click="toGoodDetail(item.productId)">
                                <el-image :src="item.thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 80px; height: 80px;">
                                    <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 80px; height: 80px;">
                                </el-image>
                            </div>
                            <div class="ml-20 good-info">
                                <p class="title" @click="toGoodDetail(item.productId)">{{item.title || item.productName}}</p>
                                <p class="color-666 mt-10">
                                    规格：{{item.specTitle}}
                                    <template v-if="item.unit">
                                        /{{item.unit}}
                                    </template>
                                    <template v-if="item.skuTitle || item.optionname">
                                        /{{item.skuTitle || item.optionname}}
                                    </template>
                                </p>
                            </div>
                        </div>
                        <div class="tr-price">
                            <template v-if="item.productType == 5">￥{{item.marketprice.toFixed(2)}}+{{item.dedcredit}}积分</template>
                            <template v-else>￥{{item.marketprice.toFixed(2)}}</template>
                        </div>
                        <div class="tr-amount">
                            <el-input-number v-model="item.total" :min="1" 
                                    :max="item.maxbuy ? item.maxbuy : 9999" 
                                    @change="(currentValue, oldValue) => ChangeNumFn(currentValue, oldValue, index)" size="mini"></el-input-number>
                        </div>
                        <div class="tr-sum font-14 color-orange">
                            <template v-if="item.productType == 5">￥{{(item.marketprice*item.total).toFixed(2)}}+{{item.dedcredit*item.total}}积分</template>
                            <template v-else>￥{{(item.marketprice*item.total).toFixed(2)}}</template>
                        </div>
                        <div class="tr-operation">
                            <span class="delete" @click="deleteFn(index)">删除</span>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>
            <div class="total-container">
                <div class="total-wrap flex justify-s-b align-c">
                    <div class="flex align-c">
                        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                        <span class="delete-all" @click="deleteAllFn">删除选中商品</span>
                    </div>
                    <div class="flex align-c">
                        <span>已选择<em class="color-orange font-bold">{{checkedItems.length}}</em>种商品，共<em class="color-orange font-bold">{{checkedgoodNum}}</em>件</span>
                        <span class="ml-20">合计：
                            <em class="color-orange font-20 font-bold">￥{{totalPrice.toFixed(2)}}元</em>
                            <em  v-if="dedcredit" class="color-orange font-20 font-bold">+{{dedcredit}}分</em>
                        </span>
                        <div class="settlement" @click="settlementFn">去结算</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import titleText from '@/components/titleText'
import Empty from '@/components/empty'

export default {
    name: 'Cart',
    components: { 
        titleText,
        Empty
    },

    data() {
        return {
            checkAll: true,
            checkedItems: [],
            goodIdList: [],
            goodList: [],
            checkedgoodNum: 0,
            totalPrice: 0,
            dedcredit: 0
        };
    },

    created() {
        this.getCartList()
    },

    methods: {
        getCartList() {
            this.$https.get(this.$api.cartList,{
                centreId: this.$store.state.userInfo.centreId,
                memberId: this.$store.state.userInfo.id,
                limit: 0,
                offset: 0
            })
            .then((res) =>{
                res.data.rows.forEach(item => {
                    this.checkedItems.push(item.id)
                    this.goodIdList.push(item.id)
                    this.checkedgoodNum += item.total
                    this.totalPrice += item.total * item.marketprice
                    if(item.productType == 5) {
                        this.dedcredit += item.total * item.dedcredit
                    }
                })
                this.goodList = res.data.rows
            })
        },

        //全选切换
        handleCheckAllChange(val) {
            this.checkedItems = val ? this.goodIdList : []
            this.totalPriceAndNumFn()
        },

        //单个选中切换
        handleCheckedItemsChange(value) {
            let checkedCount = value.length
            this.checkAll = checkedCount === this.goodIdList.length
            this.totalPriceAndNumFn()
        },

        //改变数量
        ChangeNumFn(currentValue, oldValue, index) {
            this.$https.get(this.$api.cartEdit,{
                mid: this.$store.state.userInfo.id,
                centreId: this.$store.state.userInfo.centreId,
                id: this.goodList[index].id,
                total: currentValue,
                isKuaidi: 0
                })
            .then((res) =>{
                let value = currentValue - oldValue
                let cartTotal = this.$store.state.cartTotal + value
                this.$store.commit('SET_CART_TOTAL', cartTotal)
                this.totalPriceAndNumFn(value, index)
            }).catch(() =>{
                this.goodList[index].total = oldValue
            })
        },

        //删除商品
        deleteFn(index) {
            let id = this.goodList[index].id
            this.$confirm('确定删除该商品吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$https.get(this.$api.cartDel,{
                    itemId: this.goodList[index].id,
                    memberId: this.$store.state.userInfo.id,
                    centreId: this.$store.state.userInfo.centreId,
                    isKuaidi: 0
                })
                .then((res) =>{
                    let value = this.goodList[index].total
                    let cartTotal = this.$store.state.cartTotal - value
                    this.$store.commit('SET_CART_TOTAL', cartTotal)
                    this.goodList.splice(index, 1)
                    this.goodIdList.splice(index, 1)
                    this.resetCheck(id)
                    this.totalPriceAndNumFn()
                })   
            }).catch(() => {}) 
        },

        //删除选中项
        deleteAllFn() {
            if(this.checkedItems.length == 0) return
            let idStr = ''
            this.checkedItems.forEach((item,i) => {
                if(i == this.checkedItems.length-1){
                    idStr += item
                } else {
                    idStr += `${item},`
                }
            })
            this.$confirm('确定删除所选商品吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$https.post(this.$api.cartDelAll,{
                    id: idStr,
                    memberId: this.$store.state.userInfo.id,
                    centreId: this.$store.state.userInfo.centreId,
                    isKuaidi: 0
                },{
                    dataType: "json"
                })
                .then((res) =>{
                    let value = 0
                    let len = 0
                    let goodList = JSON.parse(JSON.stringify(this.goodList))
                    let goodIdList = JSON.parse(JSON.stringify(this.goodIdList))
                    for (let i=0; i<this.goodList.length; i++){
                        let j = this.checkedItems.indexOf(this.goodList[i].id)
                        if (j > -1){
                            value += this.goodList[i].total
                            goodList.splice(i-len, 1)
                            goodIdList.splice(i-len, 1)
                            len += 1
                        }
                    }
                    this.checkedItems = []
                    this.goodList = goodList
                    this.goodIdList = goodIdList
                    let cartTotal = this.$store.state.cartTotal - value
                    this.$store.commit('SET_CART_TOTAL', cartTotal)
                    this.totalPriceAndNumFn()
                })   
            }).catch(() => {})
        },

        //重置复选框
        resetCheck(id){
            for(let i=0; i<this.checkedItems.length; i++) {
                if(this.checkedItems[i] == id) {
                    this.checkedItems.splice(i, 1)
                    break
                }
            }
            this.checkAll = this.checkedItems.length === this.goodIdList.length
        },

        //计算商品总件数和价格
        totalPriceAndNumFn(value, index){
            if(this.checkAll && value) {
                this.checkedgoodNum += value
                this.totalPrice += value * this.goodList[index].marketprice
                if(this.goodList[index].productType == 5) {
                    this.dedcredit += value * this.goodList[index].dedcredit
                }
            } else if(this.checkedItems.length == 0) {
                this.checkedgoodNum = 0
                this.totalPrice = 0
                this.dedcredit = 0
            } else {
                let checkedgoodNum = 0
                let totalPrice = 0
                let dedcredit = 0
                for (let i=0; i<this.goodList.length; i++){
                    let j = this.checkedItems.indexOf(this.goodList[i].id)
                    if (j > -1){
                        checkedgoodNum += this.goodList[i].total
                        totalPrice += this.goodList[i].total * this.goodList[i].marketprice
                        if(this.goodList[i].productType == 5) {
                            dedcredit += this.goodList[i].total * this.goodList[i].dedcredit
                        }
                    }
                }
                this.checkedgoodNum = checkedgoodNum
                this.totalPrice = totalPrice
                this.dedcredit = dedcredit
            }
        },

        //跳转商品详情
        toGoodDetail(id) {
            let { href } = this.$router.resolve({
                                name: "goodDetail",
                                query: {
                                    id: id
                                }
                            });
            window.open(href, '_blank')
        },

        settlementFn() {
            if(this.checkedItems.length <= 0) return
            let items = []
            for (let i=0; i<this.goodList.length; i++){
                let j = this.checkedItems.indexOf(this.goodList[i].id)
                if (j > -1){
                    items.push({
                        cartId: this.goodList[i].id,
                        productId: this.goodList[i].productId,
                        total: this.goodList[i].total,
                        skuId: this.goodList[i].skuId,
                        skuTitle: this.goodList[i].skuTitle,
                        marketprice: this.goodList[i].marketprice,
                        productType: this.goodList[i].productType
                    })
                }
            }
            this.$store.commit('SET_CART_ITEMS', items)
            this.$router.push({name: 'confirmOrder'})
        }

    }
}
</script>
<style lang='scss' scoped>
    .th{
        height: 36px;
        padding: 0 20px;
        border: 1px solid #DEDEDE;
        background-color: #f1f1f1;
        .th-checked{
            flex: 0 0 160px;
        }
        .th-info{
            flex: 0 0 450px;
        }
        .th-price{
            flex: 0 0 155px;
        }
        .th-amount{
            flex: 0 0 170px;
        }
        .th-sum{
            flex: 0 0 155px;
        }
        .th-operation{
            flex: 0 0 70px;
        }
    }
    .cart-container{
        padding: 0 19px;
        border: 1px solid #DEDEDE;
        background-color: rgba(12,56,144,.07);
    }
    .tr{ 
        padding: 15px 0;
        border-bottom: 1px solid #DEDEDE;
        &:last-child{
            border: none;
        }
        .tr-checked{
            flex: 0 0 30px;
        }
        .tr-info{
            flex: 0 0 580px;
            .good-info{
                max-width: 300px;
            }
        }
        .tr-price{
            flex: 0 0 155px;
        }
        .tr-amount{
            flex: 0 0 170px;
        }
        .tr-sum{
            flex: 0 0 155px;
        }
        .tr-operation{
            flex: 0 0 70px;
            .delete{
                color: #666;
                cursor: pointer;
                &:hover{
                    color: #999;
                }
            }
        }
        .good-image{
            cursor: pointer;
            border: 1px solid #DEDEDE;
        }
        .title{
            cursor: pointer;
            &:hover{
                color: var(--blue-color);
                text-decoration: underline;
            }
        }
    }

    .total-container{
        height: 50px;
        margin: 20px 0;
        .total-wrap{
            width: 1200px;
            height: 50px;
            padding-left: 20px;
            background-color: #dedede;
            .delete-all{
                cursor: pointer;
                margin-left: 25px;
                &:hover{ 
                    color: var(--blue-color);
                }
            }
            .settlement{
                width: 120px;
                height: 50px;
                margin-left: 20px;
                line-height: 50px;
                color: #fff;
                font-size: 20px;
                text-align: center;
                cursor: pointer;
                background-color: var(--orange-color);
            }
        }
    }
</style>